
import { Vue, Component } from 'vue-property-decorator';
import FindPassword from '@/views/user/find-password.vue';

@Component({
  name: 'AppFindPassword',
  components: {
    FindPassword,
  },
})
export default class extends Vue {
}
